body {
    background-color: #f3f5f7;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }
  
  .card {
    background-color: var(--background-color);
    box-shadow: 0 0 5px var(--border-color-secondary);
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px; */
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
  }
  
  .card .about {
    height: 40%;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
  }
  
  .card .about h3,
  .card .about .lead {
    font-weight: 300;
    margin: 0;
  }
  
  .card .about h3 {
    font-size: 24px;
  }
  
  .card .about .lead {
    color: #aaa;
  }
  
  .card .info {
    float: left;
    padding: 10px 20px 10px 0;
  }
  
  .card .info p {
    /* display: inline-block; */
    margin: 0;
    font-size: 11px;
    color: #aaa;
    font-weight: 300;
  }
  
  .legends {
    padding-top: 10px;
    overflow: hidden;
  }
  
  .legend {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 50%;
  }
  
  .legend--this {
    background-color: #5555FF;
  }
  
  .legend--prev {
    background-color: #FF55B8;
  }
  
  .axis {
    position: absolute;
    color: #fff;
    z-index: 1;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    bottom: 0;
  }
  
  .axis .tick {
    flex: 1;
    position: relative;
    overflow: hidden;
    opacity: 0.2;
    font-size: 11px;
    text-align: center;
    line-height: 40px;
    padding-top: 110px;
  }
  
  .axis .tick:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .axis .tick .value {
    transform: translateY(-40px);
    transition: 0.3s transform;
    position: absolute;
    top: 20px;
    color: #444;
    border-radius: 2px;
    width: 100%;
    line-height: 20px;
  }
  
  .axis .tick:hover .value.value--this {
    transform: translateY(0);
    display: block;
  }
  
  .value.value--this {
    color: #5555FF;
    font-weight: bold;
  }
  
  
canvas {
    transform: translate(0, 1px);
    position: absolute;
    bottom: 0px;
    height: 100%;
    width: 100%;
}

.react-grid-item {
    transition: all 0.3s ease;
    background-color: var(--background-color);
    box-shadow: 0 0 5px var(--border-color-secondary);
    border-radius: 1rem;
    overflow: hidden;
}

.react-resizable-handle {
    z-index: 10;
    bottom: 2px !important;
    right: 2px !important;
    height: 5px !important;
    width: 5px !important;
    color: var(--background-color);
    background-image: none;
    fill: var(--text-color);
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}